<template>
  <div class="train">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="train-cont">
<!--      <div class="train-tab">-->
<!--        <p :class="curTrainTab == item.id ? 'active' : ''" v-for="item in trainTabList" :key="item.id" @click="changeTrainTab(item)">{{ item.name }}</p>-->
<!--      </div>-->
      <div >
        <div class="cont-top">
          <div class="tab">
            <div class="tab-item" :class="activeTabId==='1'?'active':''" @click="changeTab('1')">已激活题库</div>
            <div class="tab-item" :class="activeTabId==='2'?'active':''" @click="changeTab('2')">全部题库</div>
          </div>
          <div class="tag-r">
            <div class="but">
              <Button type="primary" @click="toCdkey">题库激活码</Button>
              &nbsp;
              <Button type="primary" @click="toTestRecord">我的刷题记录</Button>
              &nbsp;
              <Button type="primary" @click="toTestStat">我的刷题学情统计</Button>
            </div>
          </div>
        </div>
        <Spin fix v-if="loading"></Spin>
        <div v-if="curTab.id == 0 && !loading">
          <div class="search" v-if="activeTabId==='1'">
            <div class="search-item">
              <div class="name">
                {{ $t('trainingcamp_train_list_first_category') }}:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="activeDataList[index].id == item.id ? 'active' : ''" v-for="(item,fIndex) in activeDataList" :key="item.id + 'category'" @click="changeSearch(fIndex)">{{item.name}}</div>
                </div>
              </div>
            </div>
            <div class="search-item" >
              <div class="name">
                {{ $t('trainingcamp_train_list_second_category') }}:
              </div>
              <div class="cont">
                <div class="values" v-if="activeDataList.length">
                  <div :class="activeDataList[index].children[secondIndex].id == item.id ? 'active' : ''" v-for="(item,sIndex) in activeDataList[index].children" :key="item.id + 'secondCategory'" @click="changeSecondSearch(sIndex)">{{item.name}}</div>
                </div>
              </div>
            </div>
            <!-- <div class="search-item">
              <div class="name">
                权限:
              </div>
              <div class="cont">
                <div class="values" >
                  <div :class="curAuthority == item.id ? 'active' : ''" v-for="item in authorityList" :key="item.id + 'authority'" @click="changeSearch(item,'curAuthority')">{{item.name}}</div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="search" v-else>
            <div class="search-item">
              <div class="name">
                {{ $t('trainingcamp_train_list_first_category') }}:
              </div>
              <div class="cont">
                <div class="values">
                  <div :class="dataList[index].id == item.id ? 'active' : ''" v-for="(item,fIndex) in dataList" :key="item.id + 'category'" @click="changeSearch(fIndex)">{{item.name}}</div>
                </div>
              </div>
            </div>
            <div class="search-item" >
              <div class="name">
                {{ $t('trainingcamp_train_list_second_category') }}:
              </div>
              <div class="cont">
                <div class="values" v-if="dataList.length">
                  <div :class="dataList[index].children[secondIndex].id == item.id ? 'active' : ''" v-for="(item,sIndex) in dataList[index].children" :key="item.id + 'secondCategory'" @click="changeSecondSearch(sIndex)">{{item.name}}</div>
                </div>
              </div>
            </div>
            <!-- <div class="search-item">
              <div class="name">
                权限:
              </div>
              <div class="cont">
                <div class="values" >
                  <div :class="curAuthority == item.id ? 'active' : ''" v-for="item in authorityList" :key="item.id + 'authority'" @click="changeSearch(item,'curAuthority')">{{item.name}}</div>
                </div>
              </div>
            </div> -->
          </div>

          <div class="table" v-if="dataList.length">
            <div v-if="activeTabId==='1'">
              <Table v-if=" activeDataList.length" ref="selection" border :columns="columns" :data="activeDataList[index].children[secondIndex].children" :loading="loading" ></Table>
            </div>

            <Table v-else ref="selection" border :columns="columns" :data="dataList[index].children[secondIndex].children" :loading="loading" ></Table>
          </div>
        </div>
        <div v-if="curTab.id == 2 && !loading">
          <div class="table" v-if="dataList.length">
            <Table ref="selection" border :columns="groupColumns" :data="groupDataList" :loading="loading" ></Table>
          </div>
        </div>

      </div>
     <!-- <div v-if="curTrainTab == 1">
       <Material></Material>
     </div> -->
    </div>

    <Modal
        v-model="selfExamModal"
        title="自测模考"
        @on-cancel="cancelSelfExam"
        width="700px"
    >
      <div class="brush-question">
        <div class="item">
          <p class="tit">题目数量:</p>
          <div class="list">
            <div v-for="(item,index) in dataContList" :key="index">
              <p :class="dataContList[dataContIndex].id == item.id ? 'active' : ''" @click="changeDataCont(index)">{{item.name}}</p>
              <Tooltip class="list-icon">
                <Icon type="ios-help-circle-outline" size="16" />
                <div slot="content">
                  <p>{{item.tip}}</p>
                  <p v-if="item.tip2">{{item.tip2}}</p>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div class="item">
          <p class="tit">测试时长:</p>
          <div class="list">
            {{dataContList[dataContIndex].timelong}}分钟
          </div>
        </div>
        <div class="item">
          <p class="tit">及格分:</p>
          <div class="list">
            80分
          </div>
        </div>
        <div class="item">
          <p class="tit">难度等级:</p>
          <div class="list">
<!--            <RadioGroup v-model="level">-->
<!--              <Radio :label="item.id" v-for="item in levelList">{{item.name}}</Radio>-->
<!--            </RadioGroup>-->
            <div v-for="(item,index) in levelList" :key="index">
              <p :class="levelList[curLevelIndex].id == item.id ? 'active' : ''" @click="changeLevel(index)">{{item.name}}</p>
              <Tooltip class="list-icon">
                <Icon type="ios-help-circle-outline" size="16" />
                <div slot="content">
                  <p>{{item.tip}}</p>
                  <p v-if="item.tip2">{{item.tip2}}</p>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

      </div>
      <div slot="footer">
        <Button @click="cancelSelfExam">{{ $t('dataset_cancel') }}</Button>
        <Button type="primary" @click="confirmSelfExam">{{ $t('dataset_confirm') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="matchExamModal"
        title="竞赛PK"
        @on-cancel="closeMatchExam"
        width="700px"
    >
      <div class="brush-question">
        <Form ref="formValidate" :label-width="80">
          <FormItem label="时间">
            <div class="match-type">
              <div class="match-type-item" :class="matchDateIndex == index ? 'active' : ''" v-for="(item,index) in matchDateList" :key="item.id" @click="changeMatchDate(index)">{{item.name}}</div>
            </div>
<!--            <DatePicker @on-change="changeDate" :options="dateOptions" :value="formValidate.date" format="yyyy-MM-dd" type="date" placeholder="请选择时间" style="width: 200px"></DatePicker>-->
<!--            <DatePicker @on-change="changeDate" :value="formValidate.date" format="yyyy/MM/dd" type="date" placement="bottom-end" placeholder="请选择时间" style="width: 200px"></DatePicker>-->
          </FormItem>
          <FormItem label="比赛模式">
            <div class="match-type">
              <div class="match-type-item" :class="matchTypeIndex == index ? 'active' : ''" v-for="(item,index) in matchTypeList" :key="item.id" @click="changeMatchType(index)">
                {{item.name}}

              </div>
              <Tooltip>
                <Icon type="ios-help-circle-outline" size="20" />
                <div slot="content" class="match-message">
                  <p>竞赛赛：优先到达及格分(总分*60%)获胜。每道题10分，答错不减分。</p>
                  <p>限时赛：限定时间内，得分最高者获胜。每道题10分，答错不减分。</p>
                  <p>挑战赛：答对自动下一题，答错自动退出。</p>
                </div>
              </Tooltip>
            </div>
          </FormItem>
        </Form>

      </div>
      <div slot="footer">
        <Button @click="closeMatchExam">{{ $t('dataset_cancel') }}</Button>
        <Button type="primary" @click="confirmMatchExam">{{ $t('dataset_confirm') }}</Button>
      </div>
    </Modal>
    <Modal
        v-model="trainTypeModal"
        :title="trainTypeTit"
        @on-cancel="closeTrainType"
        width="700px"
    >
      <div class="train-type">
        <div class="train-type-item" :class="trainTypeIndex == index ? 'active' : ''" v-for="(item,index) in trainTypeList" :key="item.id" @click="changeTrainType(index)">
          <p>{{item.name}}</p>
          <p v-if="item.count">{{item.count}}</p>
        </div>
      </div>
      <div slot="footer">
        <Button @click="closeTrainType">{{ $t('dataset_cancel') }}</Button>
        <Button type="primary" @click="confirmTrainType">{{ $t('dataset_confirm') }}</Button>
      </div>
    </Modal>
    <TrainSeting :status="trainSetting" @close="closeTrainSetting" @confirm="confirmBrushConfig"></TrainSeting>
  </div>
</template>

<script>
// import util from "@/utils/tools.js";
import TrainSeting from '../../components/trainSetting'
// import Material from './components/train/material'

export default {
  name: "dataList",
  data(){
    // const validatorDate = function (rule, value, callback){
    //   console.log(value,Array.isArray(value))
    //   if(Array.isArray(value)){ //格式为：daterange、datetimerange检测
    //     if(value.length){
    //       value.map(function(item){
    //         if(item === ""){
    //           return callback("日期不能为空")
    //         }
    //       })
    //     }else{
    //       return callback("日期不能为空")
    //     }

    //   }
    //   return callback()
    // };
    return {
      columns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center',
        // },
        {
          title: this.$t('project_create_data_table_id'),
          key: 'child_id',
          width:100,
        },
        {
          title: this.$t('trainingcamp_train_table_name'),
          key: 'name',
          minWidth:200,
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: {
                  path: '/trainingcamp/category-portrait',
                  query: {
                    id: params.row.child_id,
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, params.row.name);
          }
        },
        {
          title: this.$t('trainingcamp_train_table_count'),
          key: 'total_quantity',
          minWidth:100,
          render: (h, params) => {
            return h('span', params.row.total_quantity > 0 ? params.row.total_quantity : '-');
          }
        },
        {
          title: this.$t('trainingcamp_train_list_have_finished'),
          key: 'have_finished',
          minWidth:100,
        },
        {
          title: this.$t('trainingcamp_train_list_wrong_count'),
          key: 'wrong_quantity',
          minWidth:100,
        },
        {
          title: this.$t('project_create_data_table_op'),
          key: 'action',
          width: 420,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  icon:params.row.have_access == '0' ? 'ios-lock' : ''
                },
                style: {
                  marginRight: '5px',
                  width:'90px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.confrimTrainDetail(params.row,'1') //0每日一练 1 全部
                  }
                }
              }, this.$t('trainingcamp_train_list_all_train')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.goTrainDetail(params.row)
                  }
                }
              }, this.$t('trainingcamp_train_list_day')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.showSelfExam(params.row)
                  }
                }
              }, '自测模考'),
              params.row.map_id > 0 ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style:{
                  marginRight:'10px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.goMaterialInfo(params.row)
                  }
                }
              }, '章节练习') : '',
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style:{
              //     marginRight:'10px'
              //   },
              //   on: {
              //     click: () => {
              //       this.curData = params.row;
              //       this.showMatch(params.row)
              //     }
              //   }
              // }, '竞赛PK'),
              h('Select', {
                props:{
                  transfer: true,//不受表格高度影响,
                  placeholder:this.$t('trainingcamp_exam_list_more'),
                  size: 'small',
                  clearable:true,
                },
                style:{
                  width:'60px',
                },
                on: {
                  'on-change':(event) => {
                    this.curData = params.row;
                    if(event == 'wrong'){  //错题本练习  3错题本4收藏本5单选6多选7判断8简单9一般10困难
                      this.$router.push({
                        path:'/trainingcamp/trainDetail',
                        query:{
                          id:this.curData.child_id,
                          dataSort:'1',   //0每日一练1全部
                          data_type:3
                        }
                      })
                    }else if(event == 'collect'){  //收藏集练习
                      this.$router.push({
                        path:'/trainingcamp/trainDetail',
                        query:{
                          id:this.curData.child_id,
                          dataSort:'1',   //0每日一练1全部
                          data_type:4
                        }
                      })
                    }else if(event == 'datatype'){  //题型练习
                      // this.trainTypeModal = true;
                      // this.trainTypeList = this.datatypeList;
                      this.trainTypeTit = '题型练习';
                      this.trainType = 'datatype';
                      this.getCategoryProfile();
                    }else if(event == 'level'){  //难度练习
                      // this.trainTypeModal = true;
                      // this.trainTypeList = this.datatypeList;
                      this.trainTypeTit = '难度练习';
                      this.trainType = 'level';
                      this.getCategoryProfile();
                    }else if(event == 'node'){  //知识点练习
                      this.trainType = 'node';
                      this.getCategoryProfile();
                    } else if(event == 'label'){  //标签练习
                      this.trainType = 'label';
                      this.getCategoryProfile();
                    }
                  }
                },
              },
              [
                h('Option',{
                  props: {
                    value: 'wrong'
                  }
                },'错题本练习'),
                h('Option',{
                  props: {
                    value: 'collect'
                  }
                },'收藏集练习'),
                h('Option',{
                  props: {
                    value: 'datatype'
                  }
                },'题型练习'),
                h('Option',{
                  props: {
                    value: 'level'
                  }
                },'难度练习'),
                h('Option',{
                  props: {
                    value: 'node'
                  }
                },'知识点练习'),
                h('Option',{
                  props: {
                    value: 'label'
                  }
                },'标签练习'),
              ])
            ]);
          }
        }
      ],
      dataList: [],
      activeDataList:[],
      activeTabId:"1",
      loading:false,
      page:1,
      pageSize: 10,
      keyword:'',
      total:0,
      selectDataList:[],
      // categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      // secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      tabList:[
        {
          name:this.$t('trainingcamp_train_category'),
          id:0,
        },
        // {
        //   name:'知识图谱',
        //   id:1,
        // },
        // {
        //   name:'自有题库',
        //   id:2,
        // }
      ],
      curTab:{
        name:this.$t('trainingcamp_train_category'),
        id:0,
      },
      authorityList:[
        {
          name:this.$t('trainingcamp_train_all'),
          id:0,
        },
        {
          name:this.$t('trainingcamp_train_opened'),
          id:1,
        },
        {
          name:this.$t('trainingcamp_train_no_opened'),
          id:2,
        },
      ],
      curAuthority:0,
      trainSetting:false,
      curData:{},
      index:0,
      secondIndex:0,
      selfExamModal:false,
      level:'',
      levelList:[
        {
          name:'不限难度',
          id:0,
          tip:'试题不限难度',
          tip2:'简单:一般:困难 = 3:5:2'
        },
        {
          name:'简单',
          id:1,
          tip:'简单:一般:困难 = 3:1:1'
        },
        {
          name:'一般',
          id:2,
          tip:'简单:一般:困难 = 1:3:1'
        },
        {
          name:'困难',
          id:3,
          tip:'简单:一般:困难 = 1:1:3'
        },
      ],
      curLevelIndex:0,
      dataContList:[
        {
          name:'100题',
          id:100,
          timelong:120,
          tip:'每题1分,满分100分'
        },
        {
          name:'50题',
          id:50,
          timelong:60,
          tip:'每题2分,满分100分'
        }
      ],
      dataContIndex:0,
      matchExamModal:false,
      // formValidate:{
      //   date:'',
      // },
      ruleValidate:{
        // date: [
        //   { required: true, message: '时间不能为空', trigger: 'blur' }
        // ],
      },
      matchTypeList:[],
      matchTypeIndex:0,
      dateOptions:{},
      // trainTimelongs:{},
      matchDateList:[],
      matchDateIndex:0,
      groupColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center',
        // },
        {
          title: this.$t('project_create_data_table_id'),
          key: 'id',
        },
        {
          title: this.$t('trainingcamp_train_table_name'),
          key: 'name'
        },
        {
          title: this.$t('trainingcamp_train_table_count'),
          key: 'data_count',
          render: (h, params) => {
            return h('span', params.row.data_count > 0 ? params.row.data_count : '-');
          }
        },
        {
          title: this.$t('trainingcamp_train_list_have_finished'),
          key: 'have_finished'
        },
        {
          title: this.$t('trainingcamp_train_list_wrong_count'),
          key: 'wrong_quantity'
        },
        {
          title: this.$t('project_create_data_table_op'),
          key: 'action',
          width: 340,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                style: {
                  marginRight: '5px',
                  width:'90px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.confrimTrainDetail(params.row,'1') //0每日一练 1 全部
                  }
                }
              }, this.$t('trainingcamp_train_list_all_train')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.goTrainDetail(params.row)
                  }
                }
              }, this.$t('trainingcamp_train_list_day')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.showSelfExam(params.row)
                  }
                }
              }, '自测模考'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.showMatch(params.row)
                  }
                }
              }, '竞赛PK')
            ]);
          }
        }
      ],
      groupDataList: [
      ],
      categoryProfileInfo:{},
      trainTypeModal:false,
      trainTypeTit:'题型练习',
      datatypeList:[],
      trainTypeList:[],
      trainTypeIndex:0,
      trainType:'',
      trainTabList:[
        {
          name:'教材练习',
          id:1,
          tab:'material'
        },
        {
          name:'认证练习',
          id:2,
          tab:'attestation'
        }
      ],
      curTrainTab:'',
      curRouteTab:'',
    }
  },
  components:{
    TrainSeting,
    // Material
  },
  watch:{
    '$store.state.user.userInfo'(newV){
      if(newV.id){
        //0 高校 1 分销商  0 显示教材  其他为认证
        if(this.curRouteTab){
          this.curTrainTab = this.trainTabList.filter((item)=>{
            return item.tab == this.curRouteTab;
          })[0].id;
        }else{
          if(this.$store.state.user.userInfo.id && this.$store.state.user.userInfo.site.type == 0){
            this.curTrainTab = 1;
          }else{
            this.curTrainTab = 2;
          }
        }
      }
    }
  },
  created(){
    this.curRouteTab = this.$route.query.tab || '';
    if(this.$route.query.firstCategoryId || this.$route.query.secondCategoryId){
      this.activeTabId="2";
    }
    this.getList();
    this.dateOptions = {
      disabledDate (date) {
        let oDate;
        oDate = Date.now() - 86400000;
        return date && date.valueOf() < oDate;
      }
    }
    //0 高校 1 分销商  0 显示教材  其他为认证
    if(this.curRouteTab){
      this.curTrainTab = this.trainTabList.filter((item)=>{
        return item.tab == this.curRouteTab;
      })[0].id;
    }else{
      if(this.$store.state.user.userInfo.id && this.$store.state.user.userInfo.site.type == 0){
        this.curTrainTab = 1;
      }else{
        this.curTrainTab = 2;
      }
    }

  },
  methods:{
    getCategoryProfile(){
      let params = {
        action:'categoryProfile',
        category_id:this.curData.child_id,
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.categoryProfileInfo = res;
        this.trainTypeModal = true;
        if(this.trainType == 'node'){
          this.trainTypeList = this.categoryProfileInfo.data_node_list;
        }else if(this.trainType == 'label'){
          this.trainTypeList = this.categoryProfileInfo.data_label_list;
        }else if(this.trainType == 'datatype'){
          this.trainTypeList = [];
          res.data_type_stats.forEach((item)=>{
            this.trainTypeList.push({
              id:item.type,
              name:res.data_types[item.type],
              count:item.count
            })
          });
        }else if(this.trainType == 'level'){
          this.trainTypeList = [];
          res.data_level_stats.forEach((item)=>{
            this.trainTypeList.push({
              id:item.level,
              name:res.data_levels[item.level],
              count:item.count
            })
          });
        }
      })
    },
    changeTrainType(index){
      this.trainTypeIndex = index;
    },
    closeTrainType(){
      this.trainTypeModal = false;
    },
    confirmTrainType(){

      if(this.trainType == 'datatype'){
        this.$router.push({
          path:'/trainingcamp/trainDetail',
          query:{
            id:this.curData.child_id,
            dataSort:'1',   //0每日一练1全部
            data_type:5,
            generate_id:this.trainTypeList[this.trainTypeIndex].id
          }
        })
      }else if(this.trainType == 'level'){
        this.$router.push({
          path:'/trainingcamp/trainDetail',
          query:{
            id:this.curData.child_id,
            dataSort:'1',   //0每日一练1全部
            data_type:6,
            generate_id:this.trainTypeList[this.trainTypeIndex].id
          }
        })
      }else if(this.trainType == 'node'){
        // this.$router.push({
        //   path:'/trainingcamp/trainDetail',
        //   query:{
        //     id:this.curData.child_id,
        //     dataSort:'1',   //0每日一练1全部
        //     data_type:8,
        //     generate_id:this.trainTypeList[this.trainTypeIndex].node_id
        //   }
        // })
        this.$router.push({
          path:'/trainingcamp/trainDetail',
          query:{
            id:this.curData.child_id,
            dataSort:'1',   //0每日一练1全部
            data_type:7,
            generate_id:this.trainTypeList[this.trainTypeIndex].label_id
          }
        })

      }else if(this.trainType == 'label'){
        this.$router.push({
          path:'/trainingcamp/trainDetail',
          query:{
            id:this.curData.child_id,
            dataSort:'1',   //0每日一练1全部
            data_type:7,
            generate_id:this.trainTypeList[this.trainTypeIndex].label_id
          }
        })
      }
    },
    changeMatchDate(index){
      this.matchDateIndex = index;
    },
    // changeDate(e){
    //   this.formValidate.date = e;
    // },
    changeMatchType(index){
      this.matchTypeIndex = index;
    },
    showMatch(){
      this.matchDateIndex = 0;
      this.matchTypeIndex = 0;
      this.matchExamModal = true;
    },
    closeMatchExam(){
      this.matchExamModal = false;
    },
    confirmMatchExam(){
      let params = {
        op:'create',
        generate_type:this.curTab.id,
        // generate_id:this.curData.child_id,
        type:3,
      };
      console.log(this.curTab.id,'this.curTab.id')
      if(this.curTab.id == 0){
        params.generate_id = this.curData.child_id;
      }else if(this.curTab.id == 2){
        params.generate_id = this.curData.id;
      }

      this.api.dataset.trainPublish(params).then((res)=>{
        //创建成功
        //组卷信息  传level
        let infoParams = {
          op:'baseInfo',
          train_id:res.train_id,
          // start_time:Math.floor(new Date().getTime()/1000), //日期的 00:00:00 秒
          // end_time:new Date(this.formValidate.date).getTime()/1000 + 86400 - 1, //日期的23:59:59 秒
          generate_data_rule:this.matchTypeList[this.matchTypeIndex].id,
          timlong_life:this.matchDateList[this.matchDateIndex].id
        }
        this.api.dataset.trainPublish(infoParams).then((res)=>{
          //发布
          let publishParams = {
            op:'release',
            train_id:res.train_id,
          }
          this.api.dataset.trainPublish(publishParams).then((res)=>{
            //发布成功进入考试
            this.$router.push({
              path:'/trainingcamp/exam',
              query:{
                id:res.train_id,
                isMatch:true
              }
            })
          })
        })
      })
    },
    changeDataCont(index){
      this.dataContIndex = index;
    },
    changeLevel(index){
      this.curLevelIndex = index;
    },
    showSelfExam(){
      this.selfExamModal = true;
    },
    cancelSelfExam(){
      this.selfExamModal = false;
    },
    confirmSelfExam(){
      let params = {
        op:'quick',
        generate_type:this.curTab.id,
        type:1,
        generate_data_rule:3,
        level:this.levelList[this.curLevelIndex].id,
        data_count:this.dataContList[this.dataContIndex].id,
        timelong:this.dataContList[this.dataContIndex].timelong
      };
      console.log(this.curTab.id,'this.curTab.id')
      if(this.curTab.id == 0){
        params.generate_id = this.curData.child_id;
      }else if(this.curTab.id == 2){
        params.generate_id = this.curData.id;
      }
      this.api.dataset.trainPublish(params).then((res)=>{
        //创建成功
        //组卷信息  传level
        // let infoParams = {
        //   op:'baseInfo',
        //   train_id:res.train_id,
        //   level:this.levelList[this.curLevelIndex].id,
        //   data_count:this.dataContList[this.dataContIndex].id,
        //   timelong:this.dataContList[this.dataContIndex].timelong
        // }
        // this.api.dataset.trainPublish(infoParams).then((res)=>{
        //   //发布
        //   let publishParams = {
        //     op:'release',
        //     train_id:res.train_id,
        //   }
        //   this.api.dataset.trainPublish(publishParams).then((res)=>{
        //     //发布成功进入考试
        //
        //     this.$router.push({
        //       path:'/trainingcamp/pre-exam',
        //       query:{
        //         id:res.train_id,
        //         isSelf:true
        //       }
        //     })
        //   })
        // })
        let trainId = res.train_id;
        let params = {
          action:'trainUserStart',
          train_id:trainId
        };

        this.api.dataset.trainExecute(params).then((res)=>{
          this.$router.push({
            path:'/exam',
            query:{
              id:trainId,
              isSelf:true
            }
          })
        })
      })
    },
    changeSearch(index){
      this.index = index;
      this.secondIndex = 0;
    },
    changeSecondSearch(index){
      this.secondIndex = index;
    },
    getList(){
      let data = {
        action:'index',
      };
      this.loading = true;
      this.api.dataset.trainExecute(data).then((res)=>{
        this.loading = false;
        this.dataList = res.category_list;

        // 已激活题库逻辑处理
        let activeList=JSON.parse(JSON.stringify(res.category_list));
        activeList.forEach((item)=>{
          item.children.forEach(item2=>{
            item2.children=item2.children.filter(e=>e.have_access==="1");
          });
        });
        activeList.forEach(item=>{
          item.children=item.children.filter(e=>e.children.length);
        });
        activeList=activeList.filter(item=>item.children.length);
        console.log(activeList,'activeListactiveList')
        this.activeDataList=activeList;

        if(this.$route.query.firstCategoryId){
          this.index = this.dataList.findIndex((item)=>{
            return item.child_id == this.$route.query.firstCategoryId;
          });
        }
        if(this.$route.query.secondCategoryId){
          this.secondIndex = this.dataList[this.index].children.findIndex((item)=>{
            return item.child_id == this.$route.query.secondCategoryId;
          });
        }
        // this.trainTimelongs = res.train_timelongs;
        // this.trainMatchRules = res.train_match_rules;
        this.matchTypeList = [];
        for(let name in res.train_match_rules){
          this.matchTypeList.push({
            name:res.train_match_rules[name],
            id:name
          })
        }
        this.matchDateList = [];
        for(let name in res.train_timelong_lifes){
          this.matchDateList.push({
            name:res.train_timelong_lifes[name],
            id:name
          })
        }

        this.groupDataList = res.group_list;

        this.datatypeList = [];
        for(let name in res.data_types){
          this.datatypeList.push({
            name:res.data_types[name],
            id:name
          })
        }

        //一级标签
        // this.categoryList = [
        //   {
        //     name:this.$t('trainingcamp_train_all'),
        //     id:'-1'
        //   },
        //   ...res.categoryFirstList
        // ];
        // //二级标签
        // this.secondCategoryList = [
        //   {
        //     name:this.$t('trainingcamp_train_all'),
        //     id:'-1'
        //   },
        //   ...res.categorySecondList
        // ];
      }).catch((err)=>{
        this.loading = false;
      })
    },
    // search(){
    //   this.page = 1;
    //   this.getList();
    // },
    // changePage(page){
    //   this.page = page;
    //   this.getList();
    // },
    goTrainDetail(data){ //每日一练
      this.trainSetting = true;
      // window.open(this.constant.URL + data.upload_path);
    },
    selectData(data){
      this.selectDataList = data;
      this.$emit('changeData',this.selectDataList)
    },
    // changePageSize(pageSize){
    //   this.pageSize = pageSize;
    // },
    changeTab(data){
      this.index=0,
      this.secondIndex=0,
      this.activeTabId = data;
    },
    confirmBrushConfig(data){ //刷题设置确认
      let params = {
        action:'userSetting',
        ...data
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.trainSetting = false;
        this.$nextTick(()=>{
          if(this.curTab.id == 0){
            this.$router.push({
              path:'/trainingcamp/trainDetail',
              query:{
                id:this.curData.child_id,
                dataSort:'0',   //0每日一练1全部
              }
            })
          }else if(this.curTab.id == 2){
            this.$router.push({
              path:'/trainingcamp/trainDetail',
              query:{
                id:this.curData.id,
                dataSort:'0',   //0每日一练1全部
                generateType:2
              }
            })
          }

        })
      }).catch((err)=>{
        this.trainSetting = false;
      })
    },
    confrimTrainDetail(data,dataSort){ //0每日一练1全部   全部确定
      if(this.curTab.id == 0){
        this.$router.push({
          path:'/trainingcamp/trainDetail',
          query:{
            id:this.curData.child_id,
            dataSort:dataSort,
          }
        })
      }else if(this.curTab.id == 2){
        this.$router.push({
          path:'/trainingcamp/trainDetail',
          query:{
            id:this.curData.id,
            dataSort:dataSort,
            generateType:2
          }
        })
      }


      // window.open(this.constant.URL + data.upload_path);
    },
    closeTrainSetting(){
      this.trainSetting = false;
    },
    toCdkey(){
      this.$router.push({
        path:'/cdkey/mycdkey',
        query:{

        }
      })
    },
    toTestRecord(data){
      this.$router.push({
        path:'/trainingcamp/mytrain',
        query:{

        }
      })
    },
    toTestStat(data){
      this.$router.push({
        path:'/trainingcamp/myteststat',
        query:{

        }
      })
    },
    // changeTrainTab(data){
    //   this.curTrainTab = data.id;
    //   this.$router.push({
    //     path:'/trainingcamp/train',
    //     query:{
    //       tab:data.tab
    //     }
    //   })
    // },
    goMaterialInfo(data){
      this.$router.push({
        path:'/cert/trainingcamp/chapters',
        query:{
          mapId:data.map_id
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.train{
  padding: 20px;
  box-sizing: border-box;

  .train-tab{
    margin-bottom: 20px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >p{
      margin-right: 30px;
      padding-bottom: 10px;
      height: 100%;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
    }
    .active{
      font-weight: bold;
      color: #4A79FF;
      border-bottom: 4px solid #5782FF;
    }
  }

  .train-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
  }
  .cont-top{
    display: flex;
    justify-content: space-between;
  }
  .tab{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    height: 45px;
    line-height: 45px;
    border-radius: 4px;
    font-size: 14px;
    cursor: default;
    >div{
      width: 200px;
      cursor: pointer;
      height: 100%;
      border: 1px solid #dfdfdf;
      &:nth-child(1){
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:nth-last-child(1){
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    >div.active{
      background-color: #2d8cf0;
      border: none;
      color:#FFFFFF;
    }
  }
  .tag-r{
      display: flex;
      justify-content: flex-start;
      align-items: right;
      height: 100%;
      text-align: right;

      .tag-r-input{
        margin-right: 10px;
      }
    }
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .table{
    margin-top: 20px;
    overflow-y: auto;
  }
  .page{
    margin: 20px 0;
    text-align: right;
  }
}
.brush-question{
  .item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tit{
      font-size: 14px;
      margin-right: 10px;
      width: 80px;
      text-align: center;
    }
    .list{
      margin: 15px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >div{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >p{
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          margin-right: 10px;
          cursor: pointer;
          background-color: #f8f8f9;
          border-radius: 4px;
        }
        .list-icon{
          margin-right: 10px;
        }
        .active{
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }
    }
  }

}
.match-type{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 38px;
  line-height: 38px;
  .match-type-item{
    margin-right: 20px;
    width: 80px;
    border: 1px solid #dcdee2;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .match-message{
    white-space: normal;
  }
  .active{
    background-color: #2d8cf0;
    color:#FFFFFF;
  }
}
.train-type{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .train-type-item{
    margin-bottom: 10px;
    padding: 5px 10px;
    //height: 38px;
    //line-height: 38px;
    margin-right: 20px;
    //width: 80px;
    border: 1px solid #dcdee2;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  .active{
    background-color: #2d8cf0;
    color:#FFFFFF;
  }
}
</style>
